import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SEO from "../components/Featured/SEO";
import { Container } from "../components/Shared";
import { TeamMemberCard } from "../components/Shared/TeamMemberCard";

const query = graphql`
  {
    data: teamJson {
      title
      description
      team {
        fullName
        position
        socialMedia
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              transformOptions: { fit: COVER, cropFocus: CENTER }
              aspectRatio: 0.75
            )
          }
        }
      }
      experts {
        description
        logos {
          name
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 200)
            }
          }
        }
      }
    }
  }
`;

function IndexPage() {
  const { data } = useStaticQuery(query);
  const { title, description, team, experts } = data;

  return (
    <div className="overflow-x-hidden">
      <SEO />
      <section>
        <Container>
          <div className="space-y-44 py-44">
            <div className="space-y-16">
              <div className="text-center space-y-10">
                <h2 className="text-2xl md:text-3xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {title}
                </h2>
                <p className="text-sm uppercase text-white font-serif tracking-widest leading-loose mx-auto md:max-w-[90%] lg:max-w-[80%]">
                  {description}
                </p>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {Object.keys(team).map((key) => (
                  <TeamMemberCard data={team[key]} key={key} />
                ))}
                <div className="relative p-2 bg-second shadow-lg">
                  <div className="absolute inset-0 border-2 border-third" />
                  <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
                  <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
                  <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
                  <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
                  <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
                  <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
                  <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
                  <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
                  <div className="w-full h-full py-12 px-6 flex flex-col justify-center items-center text-center font-serif bg-gradient-to-br from-second/50 to-second/60 backdrop-blur-md">
                    <div className="text-4xl tracking-widest leading-loose">
                      +17
                    </div>
                    <p className="text-xs tracking-widest leading-loose">
                      other furiously smart team members from monster bunny
                      studios
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-16">
              <p className="text-sm uppercase text-white text-center font-serif tracking-widest leading-loose mx-auto md:max-w-[90%] lg:max-w-[80%]">
                {experts.description}
              </p>
              <div className="w-full flex items-center gap-12 snap-x snap-mandatory overflow-x-auto">
                {Object.keys(experts.logos).map((key) => {
                  const image = getImage(experts.logos[key].image);
                  return (
                    <div key={key} className="snap-always snap-center shrink-0">
                      <GatsbyImage
                        image={image}
                        alt={experts.logos[key].name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default IndexPage;
